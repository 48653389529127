import React, { Component } from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";
import Newcode from "./Newcode";
import CompLoc from "./CompLoc";
import EmodQuestions from "./EmodQuestions";
import LoadingOverlay from "react-loading-overlay";
import {
  submitCompPro,
  stepOneGet,
  sendEmail,
  resendOTPMethod,
  verifyOTP,
  reset,
  setStateAndCity,
  getEmodStatesData,
} from "../../../utils/form_compPro";
import {
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
} from "../../../utils/common";
import {
  cookie,
  cookie2,
  setCookie,
  setProspectDetails,
  deleteAddress,
  setAddressDetails,
} from "../../../utils/session_data";
import NewProgress from "../../../newcomponent/common/NewProgress";
import $ from "jquery";
import SmallLoader from "../../common/SmallLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../../config";
import store from "../../../redux/store/index";
import StateList from "../../common/StateList";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import {
  submitComrRate,
  dropReadFile,
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  zeroDefault,
  handleCheck,
  getMinMax,
  removeStateAggregateData,
} from "../../../utils/form_cmprRates";
import FormCompLoc from "./FormCompLoc";
import StateAggr from "./StateAggr";
import { clearErrHistoricalClaims } from "../../../utils/common";
import Dropzone from "react-dropzone";
import {
  validateAll,
  submit,
} from "../../../utils/SubmitCompanyProfileData.js";
import { v4 as uuidv4 } from "uuid";
const numeral = require("numeral");
let myConfig = new AWS.Config();
myConfig.update({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAJB3XUTJTLJMI4BHA",
    secretAccessKey: "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",
  },
});
let ismobile = window.innerWidth < 992;
var prospect, addressCount, loggedin;
class FormCompPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      role: "",
      selDateBus: null,
      selDateEff: moment.utc(),
      selDateExp: moment.utc().add(1, "year"),
      header: {},
      childrenLoc: {},
      uploadingFile: false,
      awaitingFile: false,
      error: false,
      errorMsg: "",
      urlSuccess: false,
      urlFail: false,
      basicInfoFail: false,
      stateInfoFail: false,
      signedUrl: "",
      isEffDropDown: false,
      existingClient:
        sessionStorage.getItem("existingClient") === "true" ? true : false,
      companyProfile: {
        companyName: {},
        emailId: {},
        effectiveDate: { value: moment.utc() },
        wcProgram: {},
        payroll_In_OH_ND_WA_WY: {},
        fein: {},
        entityType: {},
        yearBusinessEstablished: { value: moment.utc().subtract(3, "y") },
        startDateWithExtensis: { value: moment.utc() },
        expiryDate: { value: moment.utc().add(1, "y") },
      },
      prevEmailId: {},
      historicalClaimsError: false,
      historicalClaims: {},
      historicalClaimsCheck: {},
      historicalClaimsErrorStore: {},
      emodQuestions: {},
      emodStatesData: {},
      effectiveDateLoader: false,
      secondAPI: sessionStorage.getItem("secondAPI") === "true" ? true : false,
      stateList: sessionStorage.getItem("existingStateList")
        ? JSON.parse(sessionStorage.getItem("existingStateList"))
        : undefined,
      fetchingEmod: false,
    };
    this.setStateAndCity = setStateAndCity.bind(this);
    this.handleCheck = handleCheck.bind(this);
    this.clearErr = clearErrHistoricalClaims.bind(this);
    this.zeroDefault = zeroDefault.bind(this);
    this.submit = submit.bind(this);
  }

  handleTotPaidBlur = (e) => {
    // zeroDefault(e);
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];
    historicalClaims = histClaimCalcTP(historicalClaims, year);
    this.setState({ historicalClaims });
  };

  handleChange = (e, key) => {
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];

    if (!historicalClaims[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }
    this.setState({ historicalClaims });
  };

  createTable = () => {
    let table = [];
    let j = 0;
    let {
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsErrorStore,
      submitClicked,
      companyProfile,
    } = this.state;

    historicalClaimsErrorStore = !historicalClaimsErrorStore
      ? {}
      : historicalClaimsErrorStore;
    historicalClaims = !historicalClaims ? {} : historicalClaims;
    historicalClaimsCheck = !historicalClaimsCheck ? {} : historicalClaimsCheck;

    let yearBusinessEstablished = this.state.companyProfile
      .yearBusinessEstablished.value;
    let effectiveDate = this.state.companyProfile.effectiveDate.value;
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let historyLimitYear = new Date(yearBusinessEstablished).getFullYear();
    let backgroundColor = "";
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;

    rowIdYear = new Date(effectiveDate).getFullYear();
    rowIdDupYear = new Date(effectiveDate).getFullYear();

    // if (currProspect && currProspect.effectiveDate) {
    //   rowIdYear = new Date(currProspect.effectiveDate).getFullYear();
    //   rowIdDupYear = new Date(currProspect.effectiveDate).getFullYear();
    // } else {
    //   rowIdYear = new Date().getFullYear();
    //   rowIdDupYear = new Date().getFullYear();
    // }
    // if (currProspect && currProspect.businessStartDate) {
    //   historyLimitYear = new Date(currProspect.businessStartDate).getFullYear();
    // }

    {
      /*
      Note:-
      Used historyLimitYear as yearBusinessEstablished date
      (to be confirmed with aachman)

      Is this the functionality that, for the year business been established, in historical table, we restrict user to 
      check/uncheck?
    */
    }

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      // if (historicalClaimsCheck[absYear]) {
      //   backgroundColor = "#dddddd";
      // } else {
      //   backgroundColor = "";
      // }
      rowId = rowId.toString();
      if (i % 2 === 0) {
        j++;
        let yearId = Number(rowId);
        // loss_data_index = loss_data
        //   ? loss_data.findIndex((e) => e.year === rowId)
        //   : null;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className={ismobile ? `row ` : ``}>
            <td className={ismobile ? `col-sm-4` : `per30`}>
              <Form.Group>
                <label htmlFor={`carrier-${absYear}`}>Carrier</label>
                <input
                  autoComplete="off"
                  id={`carrier-${absYear}`}
                  type="text"
                  name="carText"
                  onChange={(e) => this.handleChange(e, "carrier")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].carrier
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "carrier");
                  }}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["carrier"]
                      ? historicalClaims[absYear]["carrier"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className={ismobile ? `col-sm-4` : `per10`}>
              <Form.Group>
                <label
                  htmlFor={`annPre-${absYear}`}
                  title="Annual Premium"
                  style={{ fontSize: "12px" }}
                >
                  Annual Premium
                </label>
                <NumberFormat
                  name="anualPremium"
                  id={`annPre-${absYear}`}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "annPre");
                  }}
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "annPre")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].annPre
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["annPre"]
                      ? historicalClaims[absYear]["annPre"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className={ismobile ? `col-sm-4` : `per7`}>
              <Form.Group>
                <label
                  title="Number of claims"
                  htmlFor={`claims-${absYear}`}
                  data-toggle={ismobile ? "claims" : ""}
                >
                  #Claims
                </label>
                <NumberFormat
                  name="claims"
                  id={`claims-${absYear}`}
                  maxLength="2"
                  autoComplete="off"
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "claim");
                  }}
                  onChange={(e) => this.handleChange(e, "claim")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].claim
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  className="text-center"
                  type="text"
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["claim"]
                      ? historicalClaims[absYear]["claim"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className={ismobile ? `col-sm-4` : `per10`}>
              <Form.Group>
                <label htmlFor={`totInc-${absYear}`} title="Total Incurred">
                  Total Incurred
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totInc");
                  }}
                  id={`totInc-${absYear}`}
                  autoComplete="off"
                  name="totInc"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totInc")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totInc
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={(e) => this.zeroDefault(e, "totInc")}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totInc"]
                      ? historicalClaims[absYear]["totInc"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className={ismobile ? `col-sm-4` : `per10`}>
              <Form.Group>
                <label htmlFor={`totPaid-${absYear}`} title="Total Paid">
                  Total Paid
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totPaid");
                  }}
                  onBlur={this.handleTotPaidBlur}
                  id={`totPaid-${absYear}`}
                  autoComplete="off"
                  name="totPaid"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totPaid")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totPaid
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totPaid"]
                      ? historicalClaims[absYear]["totPaid"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className={ismobile ? `col-sm-4 ` : `per10`}>
              <Form.Group>
                <label htmlFor={`totRes-${absYear}`} title="Total Reserves">
                  Total Reserves
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totRes");
                  }}
                  id={`totRes-${absYear}`}
                  autoComplete="off"
                  name="totRes"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totRes")}
                  style={{
                    backgroundColor:
                      historyLimitYear > rowId || historicalClaimsCheck[absYear]
                        ? "#dddddd"
                        : "",
                    // backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totRes
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center "
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totRes"]
                      ? historicalClaims[absYear]["totRes"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
          </tr>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
            <td className="no-coverage">
              <input
                type="checkbox"
                id={`check-${absYear}`}
                disabled={historyLimitYear > yearId}
                checked={
                  historyLimitYear > yearId || historicalClaimsCheck[absYear]
                    ? historicalClaimsCheck[absYear] || true
                    : false
                }
                onChange={(e) => this.handleCheck(e)}
              />
              No coverage for the year {yearId}
            </td>
            {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
          </tr>
        );
        absYear--;
      }
    }
    return table;
  };

  addYearToDate = (dt) => {
    dt.exp = Object.assign({}, dt);
    dt.exp.eff.add(1, "year");
    return dt;
  };
  handleDateChangeBus = (date) => {
    this.setState({
      selDateBus: date,
    });
  };
  handleDateChangeEff = (date) => {
    let { companyProfile } = this.state;
    if (date) {
      var effectiveDateVar = new Date(date);
      var startDateWithExtensisVar = new Date(
        companyProfile.startDateWithExtensis.value.toDate()
      );
      var yearBusinessExtablishedVar = new Date(
        companyProfile.yearBusinessEstablished.value.toDate()
      );

      if (startDateWithExtensisVar.getTime() > effectiveDateVar.getTime()) {
        companyProfile.startDateWithExtensis.value = moment.utc(date);
      }
      if (yearBusinessExtablishedVar.getTime() > effectiveDateVar.getTime()) {
        companyProfile.yearBusinessEstablished.value = moment.utc(date);
      }

      companyProfile.effectiveDate.value = moment.utc(date);

      // reset the historicalClaimsCheck object
      let historicalClaimsCheck = {};
      if (
        companyProfile.effectiveDate &&
        companyProfile.effectiveDate.value &&
        companyProfile.yearBusinessEstablished &&
        companyProfile.yearBusinessEstablished.value
      ) {
        let currYear = moment.utc(companyProfile.effectiveDate.value).year();
        let historyLimitYear = companyProfile.yearBusinessEstablished.value.year();
        for (let i = 0; i < 5; i++) {
          if (currYear >= historyLimitYear) {
            historicalClaimsCheck[currYear] = false;
          } else {
            historicalClaimsCheck[currYear] = true;
          }
          currYear--;
        }
      }

      this.setState({ companyProfile, historicalClaimsCheck });
    }
  };
  handleDateChangeYearBusinessEstablished = (date) => {
    let { companyProfile, historicalClaimsCheck } = this.state;
    if (date) {
      companyProfile.yearBusinessEstablished.value = date;

      // handle historial claim table checkboxes
      if (
        companyProfile.effectiveDate &&
        companyProfile.effectiveDate.value &&
        companyProfile.yearBusinessEstablished &&
        companyProfile.yearBusinessEstablished.value
      ) {
        let currYear = companyProfile.effectiveDate.value.year();
        let historyLimitYear = companyProfile.yearBusinessEstablished.value.year();
        for (let i = 0; i < 5; i++) {
          if (currYear >= historyLimitYear) {
            historicalClaimsCheck[currYear] = false;
          } else if (currYear < historyLimitYear) {
            historicalClaimsCheck[currYear] = true;
          }
          currYear--;
        }
      }

      this.setState({ companyProfile, historicalClaimsCheck });
    }
  };
  handleDateChangeExpDate = (date) => {
    let { companyProfile } = this.state;
    if (date) {
      companyProfile.expiryDate.value = date;
      this.setState({ companyProfile });
    }
  };
  handleDateChangeStartDateWithExtensis = (date) => {
    let { companyProfile } = this.state;
    if (date) {
      companyProfile.startDateWithExtensis.value = date;
      this.setState({ companyProfile });
    }
  };
  handleDateChangeExp = (date) => {
    this.setState({
      selDateExp: date,
    });
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    stepOneGet();
    prospect = cookie();
    addressCount = cookie2();
    loggedin = sessionStorage.getItem("isLoggedIn");
    let rand = Math.random();
    let dupChildrenLoc = {};
    let initialCountOfAddress = 1;
    if (prospect && addressCount && loggedin) {
      initialCountOfAddress = addressCount;
    }
    for (let i = 0; i < initialCountOfAddress; i++) {
      let rand = Math.random();
      dupChildrenLoc[i] = {
        number: i,
        key: rand,
        classCodesInfo: { 0: {} },
        state: {},
        zipCode: {},
        cityName: {},
      };
    }
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
    $("#loader").css("display", "block");
  }

  componentDidUpdate(prevProps) {
    const { childrenLoc, companyProfile } = this.state;
    const { getData } = this.props;
    const currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    if (
      currProspect &&
      !companyProfile.emailId.value &&
      JSON.stringify(getData) !== JSON.stringify(prevProps.getData)
    ) {
      this.setInputData(currProspect);
      if (sessionStorage.getItem("isEffDropDown")) {
        this.setState({ isEffDropDown: true });
        if (sessionStorage.getItem("effDropDown"))
          this.setState({
            effDropDown: JSON.parse(sessionStorage.getItem("effDropDown")),
          });
        this.setState({ effectiveDateLoader: false });
      }
    }

    clearDelete(childrenLoc, "addressBlock");
    if (prospect && addressCount && loggedin) {
      setProspectDetails();
      setAddressDetails();
    }
  }

  setInputData = (currProspect) => {
    let {
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck,
    } = currProspect;

    companyProfile.effectiveDate.value = moment.utc(
      companyProfile.effectiveDate.value
    );

    // var value = companyProfile.fein.value.toString().trim();
    // value = value.split("-").join("");
    // var len = value.length,
    //   dummystr = "000000000";

    // let pattern = /^\d+$/;

    // if (!pattern.test(value)) {
    //   companyProfile.fein.errMsg = "FEIN needs to be only digits";
    //   companyProfile.fein.error = true;
    // } else if (len > 0 && len < 9) {
    //   value = dummystr.slice(0, 9 - len) + "" + value;
    //   companyProfile.fein.value = value[0] + value[1] + "-" + value.slice(2);
    //   companyProfile.fein.errMsg = "";
    // } else if (len > 9) {
    //   companyProfile.fein.errMsg = "FEIN needs to be 9 digits";
    //   companyProfile.fein.error = true;
    // } else if (len === 0) {
    //   companyProfile.fein.value = undefined;
    //   companyProfile.fein.errMsg = "";
    // } else {
    //   companyProfile.fein.value = value[0] + value[1] + "-" + value.slice(2);
    //   companyProfile.fein.errMsg = "";
    // }

    let stateSet = {};
    for (let addressBlockNo in childrenLoc) {
      if (childrenLoc[addressBlockNo].state.value) {
        stateSet[childrenLoc[addressBlockNo].state.value] =
          childrenLoc[addressBlockNo].state.value;
      }
    }

    sessionStorage.setItem("stateSet", JSON.stringify(stateSet));

    companyProfile.expiryDate = !companyProfile.expiryDate
      ? {}
      : companyProfile.expiryDate;

    companyProfile.expiryDate.value = companyProfile.expiryDate.value
      ? moment.utc(companyProfile.expiryDate.value)
      : moment.utc(companyProfile.startDateWithExtensis.value).add(1, "y");

    companyProfile.startDateWithExtensis.value = moment.utc(
      companyProfile.startDateWithExtensis.value
    );
    companyProfile.yearBusinessEstablished.value = moment.utc(
      companyProfile.yearBusinessEstablished.value
    );
    for (let state in emodStatesData) {
      if ("date1" in emodStatesData[state]) {
        emodStatesData[state].date1.value = moment.utc(
          emodStatesData[state].date1.value
        );
      }
      if ("date2" in emodStatesData[state]) {
        emodStatesData[state].date2.value = moment.utc(
          emodStatesData[state].date2.value
        );
      }
    }
    this.setState({
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck,
    });
  };

  componentDidMount = () => {
    let { companyProfile, prevEmailId } = this.state;
    prevEmailId.value = companyProfile.emailId.value;
    this.setState({ prevEmailId });
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    if (currProspect) {
      if (sessionStorage.getItem("isEffDropDown")) {
        this.setState({ isEffDropDown: true });
        if (sessionStorage.getItem("effDropDown"))
          this.setState({
            effDropDown: JSON.parse(sessionStorage.getItem("effDropDown")),
          });
        this.setState({ effectiveDateLoader: false });
      }
      this.setInputData(currProspect);
    } else {
      let domain = sessionStorage.getItem("domain");
      let historicalClaimsCheck = {};
      if (
        companyProfile.effectiveDate &&
        companyProfile.effectiveDate.value &&
        companyProfile.yearBusinessEstablished &&
        companyProfile.yearBusinessEstablished.value
      ) {
        let currYear = companyProfile.effectiveDate.value.year();
        let historyLimitYear = companyProfile.yearBusinessEstablished.value.year();
        for (let i = 0; i < 5; i++) {
          if (currYear >= historyLimitYear) {
            historicalClaimsCheck[currYear] = false;
          } else {
            historicalClaimsCheck[currYear] = true;
          }
          currYear--;
        }
        this.setState({ historicalClaimsCheck });
      }
      sessionStorage.removeItem("stateSet");
      // axios
      //   .get(awsUrl2 + "/api/getDomainInputData/" + domain)
      //   .then((response) => {
      //     if (response.status === 200) {
      //       let { companyProfile, childrenLoc, emodStatesData } = response.data;
      //       currProspect = {
      //         childrenLoc,
      //         companyProfile,
      //         emodStatesData,
      //       };
      //       sessionStorage.setItem(
      //         "currProspect",
      //         JSON.stringify(currProspect)
      //       );
      //       this.setInputData(currProspect);
      //     }
      //   });
    }
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  autoFillAcordData = (data, that) => {
    console.log("data", data);
    sessionStorage.setItem("acordData", JSON.stringify(data));
    const { basic_information, state_details, location_details } = data;
    if (
      basic_information.eff_date &&
      moment.utc(basic_information.eff_date).isValid()
    ) {
      this.setState({ selDateEff: moment.utc(basic_information.eff_date) });
    }
    if (
      basic_information.exp_date &&
      moment.utc(basic_information.exp_date).isValid()
    ) {
      this.setState({ selDateExp: moment.utc(basic_information.exp_date) });
    }
    if (basic_information.fein) {
      this.setState({ fein: basic_information.fein });
    }
    if (basic_information.company_name) {
      this.setState({ company_name: basic_information.company_name });
    }
    if (basic_information.phone_number) {
      this.setState({ phone_number: basic_information.phone_number });
    }

    if (basic_information.desc) {
      $("#description").val(basic_information.desc);
    }

    if (Object.entries(location_details).length > 0) {
      for (let locNumber in location_details) {
        that.onAddChildLoc(location_details[locNumber]);
      }
      that.onrmvChild(0);
    }
  };

  uploadFile = (event) => {
    if (this.state.file) {
      var s3 = new AWS.S3(myConfig);
      // $("#loader").css("display", "block");
      this.setState({ uploadingFile: true });
      this.setState({
        error: false,
        urlSuccess: false,
        urlFail: false,
        basicInfoFail: false,
        stateInfoFail: false,
        signedUrl: "",
        awaitingFile: false,
      });

      const that = this;
      event.preventDefault();

      const formData = new FormData();
      formData.append("file", this.state.file[0]);

      axios
        .post(
          "https://5swuplkv50.execute-api.us-east-1.amazonaws.com/dev/api/fileUploadAcord",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          that.setState({ uploadingFile: false });
          that.setState({ awaitingFile: true });
          const fileName = response.data.fileName;
          const folder = fileName.split(".")[0];
          const resultFilePath = `${folder}/finalResponse.json`;
          var params = {
            Bucket: "ocr-doc-demo-acord",
            Key: resultFilePath,
            $waiter: {
              maxAttempts: 180,
              delay: 5,
            },
          };
          var getParams = {
            Bucket: "ocr-doc-demo-acord",
            Key: resultFilePath,
          };
          s3.waitFor("objectExists", params, function(err, data) {
            if (err) {
              console.log("file not yet ready", err);
              that.setState({ awaitingFile: false });
              that.setState({ error: true, errorMsg: "File not yet ready!" });
            } else {
              console.log("file created successfully", data); // successful response
              that.setState({ awaitingFile: false });
              s3.getSignedUrl("getObject", getParams, function(err, url) {
                if (err) {
                  $("#loader").css("display", "none");
                  console.log("Error", err);
                  that.setState({
                    error: true,
                    errorMsg: "Error in retreiving the file",
                  });
                } else {
                  console.log("The URL is", url);
                  fetch(url)
                    .then((response) => response.json())
                    .then((data) => {
                      if (Object.entries(data).length === 0) {
                        that.setState({ urlFail: true });
                      } else if (
                        Object.entries(data.basic_information).length === 0 &&
                        Object.entries(data.location_details).length !== 0
                      ) {
                        that.setState({ basicInfoFail: true });
                        that.autoFillAcordData(data, that);
                      } else if (
                        Object.entries(data.basic_information).length !== 0 &&
                        Object.entries(data.location_details).length === 0
                      ) {
                        that.setState({ stateInfoFail: true });
                        that.autoFillAcordData(data, that);
                      } else if (
                        Object.entries(data.basic_information).length === 0 &&
                        Object.entries(data.location_details).length === 0
                      ) {
                        that.setState({ urlFail: true });
                      } else {
                        that.setState({ urlSuccess: true, signedUrl: url });
                        that.autoFillAcordData(data, that);
                      }
                      $("#loader").css("display", "none");
                    })
                    .catch((error) => {
                      console.log(`Failed because: ${error}`);
                      $("#loader").css("display", "none");
                    });
                }
              });
            }
          });
        })
        .catch((error) => {
          $("#loader").css("display", "none");
          this.setState({ uploadingFile: false });
          console.log("++error", error);
          // handle your error
        });
    }
  };

  updateCompanyProfile = (event, fieldName) => {
    let { companyProfile } = this.state;
    companyProfile[fieldName] = { value: event.target.value };
    this.setState({ companyProfile });
  };

  updateLocationInfo = (value, locationIndex, fieldName, classCodeIndex) => {
    let {
      childrenLoc,
      emodStatesData,
      existingClient,
      fetchingEmod,
    } = this.state;
    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex] = {};
      if (existingClient) {
        childrenLoc[locationIndex].classCodesInfo[
          classCodeIndex
        ].newClassCode = true;
      }
      this.setState({ childrenLoc });
    } else {
      console.log("locationIndex: ", locationIndex);
      childrenLoc[locationIndex][fieldName] = { value: value };
      if (fieldName === "state") {
        let stateList = [],
          stateSet = {};
        for (let addressBlockNo in childrenLoc) {
          if (childrenLoc[addressBlockNo].state.value) {
            stateList.push(
              childrenLoc[addressBlockNo].state.value.toLowerCase()
            );
            stateSet[childrenLoc[addressBlockNo].state.value] =
              childrenLoc[addressBlockNo].state.value;
          }
        }
        if (stateList.length > 0) {
          let statesData = {
            ncciStates: [],
            actingNcciStates: [],
            indBureauStates: [],
          };
          sessionStorage.setItem("stateSet", JSON.stringify(stateSet));
          this.setState({ fetchingEmod: true }, () => {
            axios
              .post(
                awsUrl2 + "/api/getExpModStateCategory",
                JSON.stringify({ states: stateList })
              )
              .then((response) => {
                statesData = response.data;
              })
              .catch((error) => {
                console.log("error", error);
              })
              .finally(() => {
                let newEmodData = getEmodStatesData(statesData, emodStatesData);
                this.setState({
                  childrenLoc,
                  emodStatesData: newEmodData,
                  fetchingEmod: false,
                });
              });
          });
        } else {
          emodStatesData = {};
          this.setState({ childrenLoc, emodStatesData });
        }
      } else {
        this.setState({ childrenLoc });
      }
    }
  };

  removeClassCode = (locationIndex, classCodeIndex) => {
    let { childrenLoc } = this.state;
    delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
    this.setState({ childrenLoc });
  };

  updateClassCodesInfo = (locationIndex, classCodeIndex, fieldName, value) => {
    let { childrenLoc } = this.state;
    childrenLoc[locationIndex].classCodesInfo[classCodeIndex][fieldName] = {
      value: value,
    };
    this.setState({ childrenLoc });
  };

  updateLocationError = (locationIndex, classCodeIndex, fieldName, value) => {
    let { childrenLoc } = this.state;
    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
        fieldName
      ].error = value;
    } else {
      childrenLoc[locationIndex][fieldName].error = value;
    }
    this.setState({ childrenLoc });
  };

  resetClassCodeDescriptionData = (locationIndex) => {
    let { childrenLoc } = this.state;
    for (let index in childrenLoc[locationIndex].classCodesInfo) {
      if (
        childrenLoc[locationIndex].classCodesInfo[index].classCodeDescription
      ) {
        delete childrenLoc[locationIndex].classCodesInfo[index]
          .classCodeDescription.value;
      }
    }
    this.setState({ childrenLoc });
  };

  validEmail = (e) => {
    let { companyProfile } = this.state;
    var email = e.target.value;
    var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(email);
    if (!isValid && email !== "") {
      companyProfile.emailId.error = true;
    }
    this.setState({ companyProfile });
  };

  updateEmodInfo = (state, fieldName, value) => {
    let { emodStatesData } = this.state;
    let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
    if (dataFieldNames.includes(fieldName)) {
      emodStatesData[state][fieldName] = {
        value: value,
      };
    } else {
      emodStatesData[state][fieldName] = value;
    }

    this.setState({ emodStatesData });
  };

  submitCompPro2 = () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let costEppInputValue = currProspect?.costEppInputValue;

    let {
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck,
    } = this.state;
    this.setState({ errorMessage: undefined });

    let { flag, data } = validateAll(
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck
    );

    childrenLoc = data.childrenLoc;
    companyProfile = data.companyProfile;
    // emodStatesData = data.emodStatesData;
    historicalClaims = data.historicalClaims;
    historicalClaimsCheck = data.historicalClaimsCheck;
    let historicalClaimsError = data.historicalClaimsError,
      historicalClaimsErrorStore = data.historicalClaimsErrorStore;

    this.setState({
      childrenLoc,
      companyProfile,
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsError,
      historicalClaimsErrorStore,
    });

    let questions = {
      carrier_a: {
        sdr_factor: null,
        creditChecked: true,
        classDropDown: undefined,
        safetyCreditDown: 0,
        alcoholDropDown: 0,
        ccpapDropDown: 0,
        wosDropDown: 1,
      },
      carrier_b: {
        sdr_factor: null,
        creditChecked: true,
        classDropDown: undefined,
        safetyCreditDown: 0,
        alcoholDropDown: 0,
        ccpapDropDown: 0,
        wosDropDown: 1,
      },
      carrier_o: {
        sdr_factor: null,
        creditChecked: true,
        classDropDown: undefined,
        safetyCreditDown: 0,
        alcoholDropDown: 0,
        ccpapDropDown: 0,
        wosDropDown: 1,
      },
    };

    if (sessionStorage.getItem("existingClient") === "true") {
      if (!sessionStorage.getItem("selectedCarrier")) {
        flag = false;
        this.setState({
          errorMessage: "Carrier not available for this record.",
        });
      }
    }

    let monoList = ["OH", "ND", "WA", "WY"];
    let flag2 = false;

    if (flag) {
      for (let ke in childrenLoc) {
        if (!monoList.includes(childrenLoc[ke].state.value)) {
          flag2 = true;
          break;
        }
      }
      if (!flag2) {
        this.props.updatePopUpData({
          show: true,
          title: "Monopolistic States.",
          disableNoButton: false,
          secondButtonCallback: () => {
            window.location.href = "/dashboard";
          },
          secondButtonText: "To Dashboard",
          children: (
            <>
              <br></br>
              <span style={{ fontWeight: "bold" }}>
                {
                  "All the states entered are monopolistic. So, Quote won't be generated."
                }
              </span>
            </>
          ),
          acceptBtn: "OK",
          acceptBtnCallback: () => {
            console.log("okay");
          },
        });
      }
    }

    if (flag && flag2) {
      let effDropDown = [
        moment.utc(companyProfile.effectiveDate.value).format("MM/DD/YYYY"),
      ];
      sessionStorage.removeItem("getButtonClicked");
      sessionStorage.setItem("isEffDropDown", true);
      sessionStorage.setItem("effDropDown", JSON.stringify(effDropDown));
      this.submit(
        childrenLoc,
        companyProfile,
        emodStatesData,
        historicalClaims,
        historicalClaimsCheck,
        null,
        questions,
        undefined,
        "first",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        costEppInputValue,
        this.props.updatePopUpData
      );
    }
  };

  getEffectiveDateDropDown(value) {
    try {
      let { companyProfile } = this.state;
      this.setState({ effectiveDateLoader: true }, () => {
        axios
          .post(awsUrl2 + "/api/getExtensisMainTableData", {
            clientID: companyProfile.emailId.value,
          })
          .then((response) => {
            let effDropDown;
            if (
              response.data.effectiveDateList &&
              response.data.effectiveDateList.length > 0
            ) {
              effDropDown = response.data.effectiveDateList.map((e) => {
                return moment.utc(e, "YYYY-MM-DD").format("MM/DD/YYYY");
              });
              companyProfile.effectiveDate.value = moment.utc(
                effDropDown[0],
                "MM/DD/YYYY"
              );
              this.setState(
                {
                  isEffDropDown: true,
                  existingClient: true,
                  effDropDown,
                  companyProfile,
                  effectiveDateLoader: false,
                },
                () => {
                  this.setState({
                    secondAPI: true,
                  });
                }
              );
              sessionStorage.setItem(
                "effDropDown",
                JSON.stringify(effDropDown)
              );
            } else {
              axios
                .get(
                  awsUrl + "/api/getUserDataStatus/" + value + "/quote/getAll:"
                )
                .then((response) => {
                  if (
                    response.data.effectiveDateList &&
                    response.data.effectiveDateList.length > 0
                  ) {
                    effDropDown = response.data.effectiveDateList.map((e) => {
                      return moment.utc(e, "YYYY-MM-DD").format("MM/DD/YYYY");
                    });
                    companyProfile.effectiveDate.value = moment.utc(
                      effDropDown[0],
                      "MM/DD/YYYY"
                    );
                    this.setState(
                      {
                        effDropDown,
                        isEffDropDown: true,
                        effectiveDateLoader: false,
                        companyProfile,
                      },
                      () => {
                        this.setState({
                          secondAPI: false,
                        });
                      }
                    );
                    sessionStorage.setItem(
                      "effDropDown",
                      JSON.stringify(effDropDown)
                    );
                  } else {
                    axios
                      .post(awsUrl2 + "/api/getExtensisOpportunityData", {
                        opportunityID: companyProfile.emailId.value,
                      })
                      .then((response) => {
                        if (
                          response.data.effectiveDateList &&
                          response.data.effectiveDateList.length > 0
                        ) {
                          effDropDown = response.data.effectiveDateList.map(
                            (e) => {
                              return moment
                                .utc(e, "YYYY-MM-DD")
                                .format("MM/DD/YYYY");
                            }
                          );
                          companyProfile.effectiveDate.value = moment.utc(
                            effDropDown[0],
                            "MM/DD/YYYY"
                          );
                          this.setState(
                            {
                              isEffDropDown: true,
                              effDropDown,
                              companyProfile,
                            },
                            () => {
                              this.setState({
                                secondAPI: true,
                              });
                            }
                          );
                          sessionStorage.setItem(
                            "effDropDown",
                            JSON.stringify(effDropDown)
                          );
                        } else {
                          companyProfile.emailId.value = "";
                          companyProfile.effectiveDate.value = moment.utc();
                          this.setState({
                            isEffDropDown: false,
                            companyProfile,
                          });
                        }
                        this.setState({ effectiveDateLoader: false });
                      })
                      .catch((error) => {
                        console.log("error in getExtensisOpportunityData");
                        this.setState({ effectiveDateLoader: false });
                      });
                  }
                })
                .catch((error) => {
                  console.log("Error in API: ", error);
                  this.setState({ effectiveDateLoader: false });
                });
            }
          })
          .catch((error) => {
            console.log("error in getExtensisMainTableData");
            this.setState({ effectiveDateLoader: false });
          });
      });
    } catch (error) {
      this.setState({ effectiveDateLoader: false });
    }
  }

  clearAllFields = () => {
    sessionStorage.clear();
    let { companyProfile, emodStatesData, childrenLoc } = this.state;
    companyProfile = {
      companyName: { value: "" },
      emailId: { value: companyProfile.emailId.value },
      wcProgram: { value: "" },
      effectiveDate: { value: moment.utc() },
      payroll_In_OH_ND_WA_WY: {
        value: "",
      },
      fein: { value: "" },
      entityType: { value: "" },
      yearBusinessEstablished: { value: moment.utc().subtract(3, "y") },
      startDateWithExtensis: { value: moment.utc() },
      expiryDate: { value: moment.utc().add(1, "y") },
    };
    emodStatesData = {};
    childrenLoc = {};
    this.setState({
      companyProfile,
      emodStatesData,
      childrenLoc: {
        0: {
          number: 0,
          key: Math.random(),
          classCodesInfo: { 0: {} },
          state: {},
          zipCode: {},
          cityName: {},
        },
      },
      historicalClaims: {},
      historicalClaimsCheck: {},
    });
  };

  handleOnBlurEffDate(event) {
    try {
      let { companyProfile, prevEmailId } = this.state;
      if (prevEmailId.value !== event.target.value) {
        this.clearAllFields();
        if (event.target.value && event.target.value !== "") {
          this.getEffectiveDateDropDown(event.target.value);
        } else if (event.target.value === "") {
          this.setState({ isEffDropDown: false });
        }
      }
    } catch (error) {
      console.log("error in handleBlurEffDate");
    }
  }

  createHistoricalClaimsCheck = (companyProfile) => {
    let historicalClaimsCheck = {};
    if (
      companyProfile.effectiveDate &&
      companyProfile.effectiveDate.value &&
      companyProfile.yearBusinessEstablished &&
      companyProfile.yearBusinessEstablished.value
    ) {
      let currYear = moment.utc(companyProfile.effectiveDate.value).year();
      let historyLimitYear = moment
        .utc(companyProfile.yearBusinessEstablished.value)
        .year();

      for (let i = 0; i < 5; i++) {
        if (currYear >= historyLimitYear) {
          historicalClaimsCheck[currYear] = false;
        } else {
          historicalClaimsCheck[currYear] = true;
        }
        currYear--;
      }
    }
    this.setState({ historicalClaimsCheck });
  };

  setExistingStatesList = (payrollData) => {
    let stateList = [];
    for (let stateIndex in payrollData) {
      let state;
      try {
        state = payrollData[stateIndex].state.value.toLowerCase();
        if (!stateList.includes(state)) {
          stateList.push(state);
        }
      } catch (error) {}
    }

    sessionStorage.setItem("existingStateList", JSON.stringify(stateList));
    this.setState({ stateList });
  };

  handleGetButtonClick(event) {
    event.preventDefault();
    let { companyProfile, secondAPI, existingClient } = JSON.parse(
      JSON.stringify(this.state)
    );
    if (
      companyProfile.emailId.value === undefined ||
      companyProfile.emailId.value === null ||
      companyProfile.emailId.value === "" ||
      companyProfile.effectiveDate.value === undefined ||
      companyProfile.effectiveDate.value === null ||
      companyProfile.effectiveDate.value === ""
    )
      return;

    sessionStorage.setItem("isEffDropDown", true);

    if (secondAPI) {
      $("#loader").css("display", "block");
      let urlEndpoint;
      let requestBody;
      if (existingClient) {
        urlEndpoint = "getExtensisMainTableData";
        requestBody = {
          clientID: companyProfile.emailId.value,
          effectiveDate: moment
            .utc(companyProfile.effectiveDate.value)
            .format("YYYY-MM-DD"),
        };
      } else {
        urlEndpoint = "getExtensisOpportunityData";
        requestBody = {
          opportunityID: companyProfile.emailId.value,
          effectiveDate: moment
            .utc(companyProfile.effectiveDate.value)
            .format("YYYY-MM-DD"),
        };
      }
      axios
        .post(awsUrl2 + "/api/" + urlEndpoint, requestBody)
        .then((response) => {
          if (
            response.data.emodStatesData &&
            response.data.payrollData &&
            response.data.companyProfile
          ) {
            let {
              emodStatesData,
              payrollData,
              opportunity_id,
              effective_date,
              historicalClaims,
              historicalClaimsCheck,
              client_id,
              isSalesforce,
            } = response.data;
            response.data.companyProfile.emailId = {};
            response.data.companyProfile.effectiveDate = {};
            response.data.companyProfile.emailId.value =
              opportunity_id || client_id;
            response.data.companyProfile.effectiveDate.value = moment
              .utc(effective_date, "YYYY-MM-DD")
              .format("MM/DD/YYYY");
            response.data.companyProfile.startDateWithExtensis.value = moment.utc(
              response.data.companyProfile.startDateWithExtensis.value
            );
            response.data.companyProfile.yearBusinessEstablished.value = moment.utc(
              response.data.companyProfile.yearBusinessEstablished.value
            );

            // var value = response.data.companyProfile.fein.value
            //   .toString()
            //   .trim();
            // value = value.split("-").join("");
            // var len = value.length,
            //   dummystr = "000000000";

            // let pattern = /^\d+$/;

            // if (!pattern.test(value)) {
            //   response.data.companyProfile.fein.errMsg =
            //     "FEIN needs to be only digits";
            //   response.data.companyProfile.fein.error = true;
            // } else if (len > 0 && len < 9) {
            //   value = dummystr.slice(0, 9 - len) + "" + value;
            //   response.data.companyProfile.fein.value =
            //     value[0] + value[1] + "-" + value.slice(2);
            //   response.data.companyProfile.fein.errMsg = "";
            // } else if (len > 9) {
            //   response.data.companyProfile.fein.errMsg =
            //     "FEIN needs to be 9 digits";
            //   response.data.companyProfile.fein.error = true;
            // } else if (len === 0) {
            //   response.data.companyProfile.fein.value = undefined;
            //   response.data.companyProfile.fein.errMsg = "";
            // } else {
            //   response.data.companyProfile.fein.value =
            //     value[0] + value[1] + "-" + value.slice(2);
            //   response.data.companyProfile.fein.errMsg = "";
            // }

            if (response.data.selectedCarrier) {
              sessionStorage.setItem(
                "selectedCarrier",
                response.data.selectedCarrier
              );
            }

            if (isSalesforce) {
              sessionStorage.setItem("isSalesforce", "true");
            }

            response.data.companyProfile.expiryDate = {
              value: moment.utc(effective_date).add(1, "y"),
            };
            companyProfile = response.data.companyProfile;
            this.setState({
              emodStatesData,
              childrenLoc: payrollData,
              companyProfile,
            });

            if (historicalClaims && historicalClaimsCheck) {
              this.setState({ historicalClaims, historicalClaimsCheck });
            }
            if (
              !historicalClaimsCheck ||
              Object.keys(historicalClaimsCheck).length === 0
            ) {
              this.createHistoricalClaimsCheck(companyProfile);
            }

            if (existingClient) {
              sessionStorage.setItem("existingClient", true);
              try {
                sessionStorage.setItem(
                  "compositeChargeRate",
                  JSON.stringify(response.data.composite_charge_rate)
                );
                sessionStorage.setItem(
                  "programManualRate",
                  JSON.stringify(response.data.program_manual_rate)
                );
              } catch (error) {}
              this.setExistingStatesList(payrollData);
            } else {
              sessionStorage.removeItem("existingClient");
            }
            if (secondAPI) {
              sessionStorage.setItem("secondAPI", true);
            } else {
              sessionStorage.removeItem("secondAPI");
            }

            sessionStorage.setItem(
              "currProspect",
              JSON.stringify({
                emodStatesData,
                childrenLoc: payrollData,
                companyProfile: response.data.companyProfile,
                historicalClaims: historicalClaims || {},
                historicalClaimsCheck: historicalClaimsCheck || {},
              })
            );
          } else {
            sessionStorage.clear();
          }
        })
        .catch((error) => {
          console.log("error in getExtensisOpportunityData", error);
          $("#loader").css("display", "none");
        })
        .finally(() => {
          $("#loader").css("display", "none");
          return;
        });

      return;
    }

    sessionStorage.setItem("getButtonClicked", true);
    console.log(
      "inside handleGetButtonClick:\n ",
      "companyProfile.emailId.value: ",
      companyProfile.emailId.value
    );

    sessionStorage.setItem(
      "userDetails",
      JSON.stringify({
        em: companyProfile.emailId.value,
        date: moment
          .utc(companyProfile.effectiveDate.value)
          .format("MM-DD-YYYY"),
      })
    );

    $("#loader").css("display", "block");
    sessionStorage.setItem("formStage", "two");
    window.location.reload();
  }

  getInformationStatements = () => {
    let { companyProfile } = this.state;
    let {
      payroll_In_OH_ND_WA_WY: monopolisticStates,
      wcProgram,
    } = companyProfile;
    let informationStatements = [];
    let statementsMap = {
      monopolisticStates: {
        Yes:
          "Coverage is provided by the state. Extensis will register, file, collect and remit premium payments on behalf of the client according to the rules and billing procedures of the state.",
      },
      wcProgram: {
        "PEO - Carve-out":
          "A Client Retained Workers' Comp CSA is generally available in NJ, NY and PA. Coverage for other states is subject to RM approval.",
        "PEO - Managed Carve-Out":
          "Extensis will coordinate and maintain Client Workers Compensation coverage. This is generally available in NJ, NY and PA. Other states will be evaluated by RM on a case-by-case basis",
      },
    };
    if ("value" in wcProgram && statementsMap.wcProgram[wcProgram.value]) {
      informationStatements.push(statementsMap.wcProgram[wcProgram.value]);
    }
    if (
      "value" in monopolisticStates &&
      statementsMap.monopolisticStates[monopolisticStates.value]
    ) {
      informationStatements.push(
        statementsMap.monopolisticStates[monopolisticStates.value]
      );
    }

    return informationStatements;
  };

  updateCheckingMinMax = (value) => {
    this.setState({ checkingMinMax: value });
  };

  render() {
    let {
      companyProfile,
      emodStatesData,
      childrenLoc,
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsError,
      isEffDropDown,
      effDropDown,
      effectiveDateLoader,
      prevEmailId,
      errorMessage,
      checkingMinMax,
      fetchingEmod,
    } = this.state;

    let informationStatements = this.getInformationStatements();

    prevEmailId = !prevEmailId
      ? sessionStorage.getItem("prevEmailId")
      : prevEmailId;
    emodStatesData = !emodStatesData ? {} : emodStatesData;
    const table = this.createTable();

    let index = 0;
    var isLoggedIn =
      sessionStorage.getItem("isLoggedIn") === undefined ||
      sessionStorage.getItem("isLoggedIn") === null
        ? false
        : sessionStorage.getItem("isLoggedIn");
    let locationCount = 1;

    let sessionStorageQuoteData = sessionStorage.getItem("quoteData");

    let effDate = companyProfile.effectiveDate.value;
    let effDropDownSet,
      effectiveDateListData = [];
    if (effDropDown) {
      effDropDownSet = new Set(effDropDown);
      let index = 0;
      effectiveDateListData = Array.from(effDropDownSet).map((item) => {
        index++;
        if (!effDate) effDate = item;
        return (
          <option
            key={index}
            value={item}
            selected={moment.utc(item).isSame(effDate)}
          >
            {item}
          </option>
        );
      });
    }

    const wcProgramListFinal = [];
    let wcProgramListFinalIndex = 0;

    const wcProgramList = [
      "Please Select",
      "PEO",
      "PEO - Managed Carve-Out",
      "PEO - Carve-out",
      "HRO - Managed Workers' Comp",
      "PEO - Open Market",
    ];

    wcProgramList.map((item) => {
      wcProgramListFinal.push(
        <option key={wcProgramListFinalIndex} value={item}>
          {item}
        </option>
      );
      wcProgramListFinalIndex++;
    });

    try {
      locationCount = Object.keys(childrenLoc).length;
    } catch (e) {}
    return (
      <div id="compPro" className="container-fluid active per98">
        {/* <NewProgress /> */}
        <div id="compProfile">
          {/* <form>
            {!isLoggedIn && (
              <p id="existUser">
                Already an existing user?{" "}
                <Link className="prominent" to={"/Login"}>
                  Sign In
                </Link>
              </p>
            )}
          </form> */}
          <form>
            <div className="formTitle">
              <h4>
                <b>WC &amp; EPP Rater</b>
                <div style={{ backgroundColor: "black" }}>
                  <hr />
                </div>
              </h4>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-5 relDiv">
                <Form.Group>
                  <Form.Label htmlFor="emailAddress">
                    Client ID / Opportunity ID
                  </Form.Label>
                  <input
                    value={companyProfile.emailId.value}
                    onClick={(event) => {
                      this.setState({
                        prevEmailId: { value: event.target.value },
                      });
                    }}
                    onChange={(event) => {
                      this.updateCompanyProfile(event, "emailId");
                    }}
                    style={{
                      ...(companyProfile.emailId.error
                        ? { borderColor: "red" }
                        : {}),
                    }}
                    onBlur={(event) => {
                      this.handleOnBlurEffDate(event);
                    }}
                    id="emailAddress"
                    type="text"
                    autoComplete="off"
                  />
                </Form.Group>
              </div>

              <div
                className={
                  ismobile
                    ? "col-sm-12 col-md-12 col-lg-2"
                    : "col-sm-10 col-lg-5"
                }
              >
                <Form.Group>
                  <Form.Label
                    htmlFor="effectiveDate"
                    title="Effective Date"
                    className="mandatory"
                  >
                    Policy Eff Dt
                  </Form.Label>
                  <LoadingOverlay
                    active={effectiveDateLoader}
                    text="Loading..."
                  >
                    <span id="effectiveDate">
                      {isEffDropDown ? (
                        <select
                          id="effectiveDateDropDown"
                          className="form-control"
                          disabled={effectiveDateLoader}
                          onChange={(event) => {
                            this.handleDateChangeEff(event.target.value);
                          }}
                          style={{ width: "100%" }}
                        >
                          {effectiveDateListData}
                        </select>
                      ) : (
                        <DatePicker
                          selected={moment.utc(effDate)}
                          onChange={this.handleDateChangeEff}
                          disabled={effectiveDateLoader}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                          // className="effective_input"
                        />
                      )}
                    </span>
                  </LoadingOverlay>
                  <p className="errMsg" />
                </Form.Group>
              </div>

              <div className="col-md-12 col-lg-1 pt-3">
                <button
                  className="btn btn-sm get-button"
                  disabled={effectiveDateLoader}
                  onClick={(event) => this.handleGetButtonClick(event)}
                  style={{
                    backgroundColor: "#ff5a54",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    marginTop: "7px",
                    borderRadius: "20px",
                  }}
                >
                  Get
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-5">
                <Form.Group>
                  <Form.Label htmlFor="companyName" className="mandatory">
                    Company Name
                  </Form.Label>
                  <input
                    value={companyProfile.companyName.value}
                    id="companyName"
                    type="text"
                    style={{
                      ...(companyProfile.companyName.error
                        ? { borderColor: "red" }
                        : {}),
                    }}
                    onChange={(event) =>
                      this.updateCompanyProfile(event, "companyName")
                    }
                    autoComplete="off"
                    className="form-control"
                  />
                  <p className="errMsg" />
                </Form.Group>
              </div>

              <div className="col-lg-3 col-sm-12">
                <Form.Group>
                  <Form.Label htmlFor="wcProgram" className="mandatory">
                    Choose WC Program
                  </Form.Label>
                  <select
                    value={companyProfile.wcProgram.value}
                    id="wcProgram"
                    type="text"
                    style={{
                      ...(companyProfile.wcProgram.error
                        ? { borderColor: "red" }
                        : {}),
                    }}
                    onChange={(event) =>
                      this.updateCompanyProfile(event, "wcProgram")
                    }
                    autoComplete="off"
                    className="form-control"
                  >
                    {wcProgramListFinal}
                  </select>
                  <p className="errMsg" />
                </Form.Group>
              </div>
              <div className="col-lg-3 col-sm-12">
                <Form.Group>
                  <Form.Label
                    htmlFor="payroll_In_OH_ND_WA_WY"
                    className="mandatory"
                  >
                    Payroll in OH, ND, WA, VI, PR or WY?
                  </Form.Label>
                  <select
                    value={companyProfile.payroll_In_OH_ND_WA_WY.value}
                    id="payroll_In_OH_ND_WA_WY"
                    type="text"
                    style={
                      companyProfile.payroll_In_OH_ND_WA_WY.error
                        ? {
                            borderColor: "red",
                          }
                        : {}
                    }
                    onChange={(event) =>
                      this.updateCompanyProfile(event, "payroll_In_OH_ND_WA_WY")
                    }
                    autoComplete="off"
                    className="form-control"
                  >
                    <option key={1} value={undefined}>
                      {" "}
                      Please Select{" "}
                    </option>
                    <option key={2} value={"Yes"}>
                      {" "}
                      Yes{" "}
                    </option>
                    <option key={3} value={"No"}>
                      {" "}
                      No{" "}
                    </option>
                  </select>

                  <p className="errMsg">
                    {companyProfile.payroll_In_OH_ND_WA_WY &&
                    companyProfile.payroll_In_OH_ND_WA_WY.errMsg
                      ? companyProfile.payroll_In_OH_ND_WA_WY.errMsg
                      : ""}
                  </p>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-sm-12">
                <Form.Group>
                  <Form.Label htmlFor="entityType">Entity Type</Form.Label>
                  <select
                    value={companyProfile.entityType.value}
                    id="entityType"
                    type="text"
                    style={{
                      ...(companyProfile.entityType.error
                        ? { borderColor: "red" }
                        : {}),
                    }}
                    onChange={(event) =>
                      this.updateCompanyProfile(event, "entityType")
                    }
                    autoComplete="off"
                    className="form-control"
                  >
                    <option key={1} value={undefined}>
                      {" "}
                      Please Select{" "}
                    </option>
                    <option key={2} value={"Corporation"}>
                      {" "}
                      Corporation{" "}
                    </option>
                    <option key={3} value={"Joint Venture"}>
                      {" "}
                      Joint Venture{" "}
                    </option>
                    <option key={4} value={"LLC"}>
                      {" "}
                      LLC{" "}
                    </option>
                    <option key={5} value={"SubCharter 'S' corp"}>
                      {" "}
                      SubCharter 'S' corp{" "}
                    </option>
                    <option key={6} value={"Not for Profit 501[c][3]"}>
                      {" "}
                      Not for Profit 501[c][3]{" "}
                    </option>
                    <option key={7} value={"Other"}>
                      {" "}
                      Other{" "}
                    </option>
                    <option key={8} value={"Partnership"}>
                      {" "}
                      Partnership{" "}
                    </option>
                    <option key={9} value={"Sole Proprietor"}>
                      {" "}
                      Sole Proprietor{" "}
                    </option>
                    <option key={10} value={"Trust"}>
                      {" "}
                      Trust{" "}
                    </option>
                  </select>
                  <p className="errMsg" />
                </Form.Group>
              </div>

              <div className="col-lg-2 col-sm-12">
                <Form.Group>
                  <Form.Label className="mandatory" htmlFor="fein">
                    FEIN
                  </Form.Label>
                  <input
                    value={companyProfile.fein.value}
                    id="fein"
                    type="text"
                    style={{
                      ...(companyProfile.fein.error
                        ? { borderColor: "red" }
                        : {}),
                    }}
                    onChange={(event) =>
                      this.updateCompanyProfile(event, "fein")
                    }
                    onInput={(event) => {
                      event.target.value = event.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*)\./g, "$1");
                    }}
                    onBlur={(event) => {
                      var value = event.target.value.toString().trim();
                      value = value.split("-").join("");
                      var len = value.length,
                        dummystr = "000000000";

                      let pattern = /^\d+$/;

                      if (!pattern.test(value)) {
                        companyProfile.fein.errMsg =
                          "FEIN needs to be only digits";
                        companyProfile.fein.error = true;
                      } else if (len > 0 && len < 9) {
                        value = dummystr.slice(0, 9 - len) + "" + value;
                        companyProfile.fein.value =
                          value[0] + value[1] + "-" + value.slice(2);
                        companyProfile.fein.errMsg = "";
                      } else if (len > 9) {
                        companyProfile.fein.errMsg =
                          "FEIN needs to be 9 digits";
                        companyProfile.fein.error = true;
                      } else if (len === 0) {
                        companyProfile.fein.value = undefined;
                        companyProfile.fein.errMsg = "";
                      } else {
                        companyProfile.fein.value =
                          value[0] + value[1] + "-" + value.slice(2);
                        companyProfile.fein.errMsg = "";
                      }
                      this.setState({ companyProfile });
                    }}
                    autoComplete="off"
                    className="form-control"
                  />
                  <p className="errMsg">
                    {companyProfile.fein.errMsg &&
                    companyProfile.fein.errMsg !== ""
                      ? companyProfile.fein.errMsg
                      : ""}
                  </p>
                </Form.Group>
              </div>

              <div
                className={
                  ismobile
                    ? "col-sm-12 col-md-12 col-lg-2"
                    : "col-sm-12 col-lg-3"
                }
              >
                <Form.Group>
                  <Form.Label
                    htmlFor="yearBusinessEstablished"
                    title="Year Business Established"
                    className="mandatory"
                  >
                    Business start date
                  </Form.Label>
                  <span id="yearBusinessEstablished">
                    <DatePicker
                      selected={companyProfile.yearBusinessEstablished.value}
                      onChange={this.handleDateChangeYearBusinessEstablished}
                      maxDate={moment.utc(companyProfile.effectiveDate.value)}
                      showYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                      // className="effective_input"
                    />
                  </span>
                  <p className="errMsg" />
                </Form.Group>
              </div>
              <div
                className={
                  ismobile
                    ? "col-sm-12 col-md-12 col-lg-2"
                    : "col-sm-12 col-lg-4"
                }
              >
                <Form.Group>
                  <Form.Label
                    htmlFor="startDateWithExtensis"
                    title={
                      process.env.REACT_APP_MODE == "beta"
                        ? "Start Date with PEO"
                        : "Start Date with Extensis"
                    }
                    className="mandatory"
                  >
                    {process.env.REACT_APP_MODE == "beta"
                      ? "Start Date with PEO"
                      : "Start Date with Extensis"}
                  </Form.Label>
                  <span id="startDateWithExtensis">
                    <DatePicker
                      selected={companyProfile.startDateWithExtensis.value}
                      onChange={this.handleDateChangeStartDateWithExtensis}
                      maxDate={moment.utc(companyProfile.effectiveDate.value)}
                      showYearDropdown
                      showMonthDropdown
                      useShortMonthInDropdown
                      className="startDateWithExtensis"
                    />
                  </span>
                  <p className="errMsg" />
                </Form.Group>
              </div>
            </div>
            {informationStatements.length > 0 && (
              <div className="information-statements">
                <h6>
                  <b>Information Statement</b>
                </h6>
                <ul>
                  {informationStatements.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="calculator">
              <div id="allLocations">
                <span className="formTitle">
                  <h4 className="company-locations">
                    <b>{"Exposure Details "}</b>
                    <div style={{ backgroundColor: "black" }}>
                      <hr />
                    </div>
                  </h4>
                </span>
                <div id="addressDiv">
                  {childrenLoc &&
                    Object.values(childrenLoc).map((e) => {
                      index++;
                      return (
                        <>
                          <CompLoc
                            index={index}
                            key={e.key}
                            locationInfo={e}
                            updateLocationInfo={this.updateLocationInfo}
                            setStateAndCity={this.setStateAndCity}
                            removeClassCode={this.removeClassCode}
                            removeLocation={this.onrmvChild}
                            updateClassCodesInfo={this.updateClassCodesInfo}
                            updateLocationError={this.updateLocationError}
                            resetClassCodeDescriptionData={
                              this.resetClassCodeDescriptionData
                            }
                            effectiveDate={companyProfile.effectiveDate}
                            locationCount={locationCount}
                            extraStates={companyProfile.payroll_In_OH_ND_WA_WY}
                            updateCheckingMinMax={this.updateCheckingMinMax}
                          />
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="mt-2">
                <button
                  type="button"
                  className="btnInvisible addLoc transpButton p-0"
                  onClick={this.onAddChildLoc}
                >
                  <img src={require("../../../images/plus4.png")} />{" "}
                  <b>Add new location</b>
                </button>
              </div>
            </div>
            <div className="emod-ques">
              {Object.values(emodStatesData).length > 0 && (
                <h4 className="emod-ques-heading">
                  <b>Experience Modification</b>
                </h4>
              )}
              {Object.keys(emodStatesData).map((e) => {
                return (
                  <EmodQuestions
                    key={e}
                    state={e}
                    emodData={emodStatesData[e]}
                    updateEmodInfo={this.updateEmodInfo}
                  />
                );
              })}
            </div>

            <div className="formTitle historical-claims">
              <h4 className="history-header">
                <b>Historical Claims (Previous Five Years)</b>
                <div style={{ backgroundColor: "black" }}>
                  <hr />
                </div>
              </h4>
            </div>
            {historicalClaimsError && (
              <p id="claims-error" className="text-danger">
                Claims submitted should be for consecutive years!
              </p>
            )}
            {/* <hr /> */}
            <div className="row grey-table-row">
              <table id="claimTable" className="grey-table">
                <thead>
                  <tr></tr>
                </thead>
                <tbody>{table}</tbody>
              </table>
            </div>
          </form>
          <div className="d-flex mt-2 flex-column align-items-center ">
            {errorMessage && <span className="errMsg">{errorMessage}</span>}
            <div className="Quote_Button">
              <button
                type="button"
                onClick={() => {
                  if (checkingMinMax || fetchingEmod) return;
                  this.submitCompPro2();
                }}
                id="profileSaveButton"
                className="btn btn-warning btn-lg btn-warning-shadow btnSubmits mt-0 "
                disabled={checkingMinMax || fetchingEmod}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onAddChildLoc = (data) => {
    let { childrenLoc, existingClient } = this.state;
    let dupChildrenLoc = childrenLoc;
    let rand = Math.random();
    const keys = Object.keys(dupChildrenLoc);
    const index = parseInt(keys[keys.length - 1]) + 1;
    if (!data.location_details) {
      data.location_details = {};
    }
    dupChildrenLoc[index] = {
      number: index,
      key: rand,
      classCodesInfo: { 0: existingClient ? { newClassCode: true } : {} },
      state: {},
      zipCode: {},
      cityName: {},
    };
    if (existingClient) {
      dupChildrenLoc[index].newLocation = true;
      dupChildrenLoc[index].effectiveDate = {
        value: moment.utc(),
      };
    }

    let stateSet = {};
    for (let addressBlockNo in childrenLoc) {
      if (childrenLoc[addressBlockNo].state.value) {
        stateSet[childrenLoc[addressBlockNo].state.value] =
          childrenLoc[addressBlockNo].state.value;
      }
    }

    sessionStorage.setItem("stateSet", JSON.stringify(stateSet));
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
  };

  onrmvChild = (addressId) => {
    let { childrenLoc, emodStatesData, fetchingEmod } = this.state;
    delete childrenLoc[addressId];

    let stateList = [],
      stateSet = {};
    for (let addressBlockNo in childrenLoc) {
      if (childrenLoc[addressBlockNo].state.value) {
        stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
        stateSet[childrenLoc[addressBlockNo].state.value] =
          childrenLoc[addressBlockNo].state.value;
      }
    }
    if (stateList.length > 0) {
      let statesData = {
        ncciStates: [],
        actingNcciStates: [],
        indBureauStates: [],
      };
      sessionStorage.setItem("stateSet", JSON.stringify(stateSet));

      this.setState({ fetchingEmod: true }, () => {
        axios
          .post(
            awsUrl2 + "/api/getExpModStateCategory",
            JSON.stringify({ states: stateList })
          )
          .then((response) => {
            statesData = response.data;
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            let newEmodData = getEmodStatesData(statesData, emodStatesData);
            this.setState({
              childrenLoc,
              emodStatesData: newEmodData,
              fetchingEmod: false,
            });
          });
      });
    } else {
      emodStatesData = {};
      this.setState({ childrenLoc, emodStatesData });
    }
  };
}

export default FormCompPro;
